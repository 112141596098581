$fa-font-path: "../fonts/fontawesome";
$slick-font-path: "../fonts/";
$slick-loader-path: "../img/";
$min-contrast-ratio: 3;
$primary: #106CB0;
$secondary: #1D1D19;
$dark: #1D1D19;

$theme-colors: (
  "primary":    $primary,
  "secondary":    $secondary,
);

$btn-border-radius: 2rem;
$btn-padding-y:  .75rem;
$btn-padding-x:     2rem ;



.header__site .top__bar{
  background-color: #f3F3F3 !important;

}

.header__site .btn--search svg{
  stroke: #fff !important;
}