footer.footer__Site{
  background-color: lighten($dark, 10);
  .social__links{
    .icon-illiwap {
      background: url("../img/icon-illiwap-villard.svg") left no-repeat;
      background-size: 40px;
      width: 20px;
      height: 20px;
    }
  }
}