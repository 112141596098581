.news{
  @include media-breakpoint-up(md) {
    article:nth-child(1) {

      .post__content{
        // background-color: $primary;
        &, *{
          color: $white;
        }
      }
    }
  }
  article.post:not(:first-child){
    // background-blend-mode: overlay;
    background-size: cover;
    padding: 0;
    a{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 3rem;
      background-color: rgba($dark, .7);
    }
    .post__categories{
      span{
        font-size: 12px;
        font-weight: bold;
        color: $primary;
      }
    }
  }
}

figure.effect-6 {
  .title{
    text-align: center;
  }
}

.agenda .event__content .event__date .date-range{
  color: white;
  font-weight: bold;
}

.agenda{
  .event__content{
    background-color: $primary;
  }
}

.fast__links{
  flex-wrap: wrap;
}

.page_menu .nav__item.active a{
  color: $secondary;
}
.page_menu ul::before {
  background-color: $secondary;
}

.header__site{
  z-index: 9999;
  border-bottom: 1px solid #eee;
  .header__wrapper{
    padding: 20px 10px;
  }
  .hamburger{
    @include media-breakpoint-down(sm) {
      top: 10px;
    }
  }
}
.header__site+main{
  @include media-breakpoint-up(lg) {
    padding-top: 144px;
  }
}

.news article.post:not(:first-child) a{
  background-color: rgba($dark, .7);
}

.news article:not(:first-child) .h3, .news article:not(:first-child) h3{
  @include media-breakpoint-up(lg) {
    border-color: $primary;
  }
}